.sensors {

    .ol-overlay > div {
        width: 100%;
        height: 100%;
    }

    .ol-overlay img {
        margin: auto;
        display: block;
        min-height: 80%;
        max-width: 100%;
        max-height: 80%;
    }

    .ol-overlay p {
        margin: 0;
        text-align: center;
    }

    .ol-overlay p.copy {
        font-size: 0.8em;
        text-align: initial;
    }

    .current-conditions {
        line-height: 1.3em;
        max-width: 460px;
        padding: 10px;
        border-width: 0;
        border-color: #fff;
        border-radius: 10px;
        margin: auto;
        width: 50%;
    }

    .popup-time-stamp {
        padding: 3px 0 3px;
        white-space: nowrap;
    }

    .popup-conditions {
        font-size: 16px;
        font-weight: 700;
        float: left;
        padding-top: 1.5em;
    }

    .popup-aqi {
        font-weight: 700;
        font-size: 40px;
        width: 100%;
        text-align: center;
    }

    .popup-history {
        font-size: initial;
        text-align: center;
    }

    .legend-tooltip-popup {
        display: inline-block;
        margin: 0.5em;
        font-size: small;
        width: 5em;
        border: 1px solid #E7B530;
        text-align: center;
    }

    .popup-more {
        float: right;
    }

    .pollution-variation-div {
        border-radius: 10px;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    }

    .info-modal {
        max-width: 95% !important;
        height: 100%;
    }

    .info-modal .modal-header {
        background-color: #E7B530;
    }

    .info-modal .card {
        border: 1px solid #E7B530;
    }

    .info-modal hr {
        border-top: 1px solid #B02B48;
    }


    .ol-control.ol-layerswitcher-popup {
        top: 0.5em;
        font-size: smaller;
        position: absolute;
        right: .5em;
        text-align: left;
        max-height: calc(100% - 120px);
        box-sizing: border-box;
        overflow: auto;
        box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, .75);
        cursor: default;
    }

    .ol-control.ol-layerswitcher-popup.ol-collapsed .panel {
        display: block;
    }

    .ol-control.ol-layerswitcher-popup.ol-collapsed .panel > li:nth-child(2) {
        display: none;
    }

    .ol-control.ol-layerswitcher-popup .panel > li:nth-child(2) {
        display: none;
    }

    .ol-control.ol-layerswitcher-popup button {
        display: none;
    }

    .ol-control.ol-layerswitcher-popup .panel {
        clear: both;
        background: #E7B530;
    }

    // .ol-layerswitcher-popup li {
    //     color: black;
    //     font-size: large;
    // }

    .ol-layerswitcher-popup li:hover {
        background: #958E7A;
        color: #fff;
    }

    .ol-layerswitcher-popup li.select, .ol-layerswitcher-popup li.ol-visible {
        background: #B02B48;
        color: #fff;
    }

    .ol-layerswitcher-popup .ol-layerswitcher-popup-definition {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 14em;
        margin-bottom: 0
    }

    .ol-layerswitcher-popup .ol-layerswitcher-popup-definition:hover {
        overflow: visible;
    }

    .ol-layerswitcher-popup hr {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .ol-layerswitcher-popup .marquee {
        white-space: nowrap;
        overflow: hidden;
        font-size: small;
    }

    .ol-layerswitcher-popup .marquee span {
        display: inline-block;
        width: 100%;
    }

    .ol-layerswitcher-popup .marquee span span {
        transition: left 5s linear;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        left: 0;
    }

    .ol-layerswitcher-popup .marquee:active span, .marquee:hover span {
        width: auto;
    }

    .ol-layerswitcher-popup .marquee:active span span, .marquee:hover span span {
        left: calc(13em - 100%);
    }

    .ol-layerswitcher-popup .ol-layerswitcher-popup-description {
        max-width: 14em;
        font-size: small;
        height: 2em;
        line-height: 1em;
        text-overflow: ellipsis;
        margin-bottom: 0;
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
        display: -webkit-box;
    }

    .ol-layerswitcher-popup .ol-layerswitcher-popup-description:hover {
        overflow: visible;
        white-space: normal;
        height: auto;
    }

    .ol-popup.default > div {
        background-color: #fff;
        border: 1px solid #E7B530;
        border-radius: 5px;
    }

    .ol-control button {
        background-color: #B02B48;
    }

    .ol-popup .closeBox {
        background-color: #B02B48;
    }

    .ol-popup {
        border-radius: 5px;
        box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, .75);
    }

    .layer-control-container {
        top: 0.5em;
        position: absolute;
        right: .5em;
        text-align: left;
        max-height: calc(100% - 120px);
        box-sizing: border-box;
        overflow: auto;
        box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, .5);
        cursor: default;
        z-index: 1000;
        border-radius: 5px;
        border: 1px solid #E7B530;
    }

    .layer-control-container .panel{
        clear: both;
        background: rgba(255,255,255,0.7);
    }

    .map-info-window .right{
        text-align: right
    }

    .map-info-window .center{
        text-align: center
    }

    .data-analysis-btn {
        position: absolute;
        bottom: 0.5em;
        right: 2em;
        background: rgb(176, 43, 72) !important;
        color: white !important;
        border-color: rgb(176, 43, 72) !important;
    }

    .time-filter-button {
        position: absolute;
        left: 0.5em;
        bottom: 0.5em;
        z-index: 1000;

        i {
            font-weight: 800;
            font-size: 120%;
        }
    }
}

.time-filter {
	position: absolute;
	bottom: 1em;
	z-index: 1000;
	background: rgba(255,255,255,0.7);
	padding: 0.5em;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -3%);
	box-sizing: border-box;
	overflow: hidden;
	box-shadow: 10px 10px 5px 0 rgba(0,0,0,.5);
	border-radius: 5px;
	border: 1px solid #E7B530;
	min-width: 45%;

	font-size: 120%;
	font-weight: 400;

	.form-group {
		justify-content: center;
		display: flex;
		flex: 0 0 auto;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 0;
	}

	input {
		color: black;
		border: 1px solid #B02B48;
		padding: 0;
	}

	@media (min-width: 1321px) and (max-width: 1520px) {
		min-width: 50%;
	}

	@media (min-width: 1200px) and (max-width: 1320px) {
		min-width: 60%;
	}

	@media (max-width: 1200px) {
		max-width: 20%;
	}

}
.css-table {

	.table {
		display: table;
		border-collapse: collapse;
		border-spacing: 2px;

		.thead {
			display: table-header-group;

			.td {
				border-bottom: 2px solid #c8ced3;
				border-top: 1px solid #c8ced3;
				vertical-align: bottom;
				font-weight: bold;
			}
		}

		.tbody {
			display: table-row-group;
		}

		.tr {
			display: table-row;
		}

		.td {
			display: table-cell;
			padding: 0.75rem;
			// vertical-align: top;
		}

	}

}
.dashboard-card {
	width: 50%;
	height: auto;
	padding: 0.5em;
	background-color: rgba(255,255,255,0.2) !important;
	border-radius: 15px !important;
	border-width: 0 !important;
	margin: 1em 3em;
	.title {
		text-align: center;
		font-weight: 800;
		font-size: 120%;
		color: #49535a;
	}
	svg {
		width: 100%;
		height: auto;
	}
}

.dashboard-card:hover {
	background-color: rgba(255,255,255,0.7) !important;
}
.ol-custom-timeslider-div {
    float: left;
    width: 100%;
    background: rgba(65,65,66,0.9);
    position: absolute;
    /* opacity: 0.8; */
    margin: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 5px;
}

.ol-custom-timeslider-slider {
    width: calc(100% - 130px);
    float: right;
    padding: 15px 10px;
    margin-right: 90px;
    height: 85px;
}

#ol-custom-timeslider-timestamp {
    position: absolute;
    bottom: 90px;
    left: calc((100% - 150px) / 2);
    display: none;
}

.fullscreen:fullscreen #ol-custom-timeslider-timestamp {
    position: absolute;
    bottom: 70px;
    left: calc((100% - 150px) / 2);
    display: block;
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
}

/*.rc-slider {*/
/*overflow-x: hidden;*/
/*overflow-y: hidden;*/
/*padding: 20px;*/
/*height: 85px;*/
/*width: calc(100% - 130px);*/
/*float: right;*/
/*margin-right: 90px;*/
/*}*/

.rc-slider-handle {
    width: 16px;
    height: 16px;
    border: solid 3px red;
}

.rc-slider-handle:hover {
    border: solid 3px red;
}

.rc-slider-handle:active {
    border: solid 3px red;
}

.rc-slider-handle-click-focused:focus {
    border: solid 3px red;
}

.rc-slider-tooltip-placement-top{
    width: 120px;
}

.play {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid #E7B530;
    /*margin: 20px auto 10px auto;*/
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    left: 15px;
    top: 12px;
}

.play:before {
    content: '';
    position: absolute;
    top: -15px;
    left: -23px;
    bottom: -15px;
    right: -7px;
    border-radius: 50%;
    border: 2px solid #E7B530;
    z-index: 2;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
}

.play:after {
    content: '';
    opacity: 0;
    transition: opacity 0.6s;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
}

.play:hover:before, .play:focus:before {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}

.play.active {
    border-color: transparent;
}

.play.active:after {
    content: '';
    opacity: 1;
    width: 14px;
    height: 16px;
    background: #E7B530;
    position: absolute;
    right: 1px;
    top: -8px;
    border-left: 4px solid #E7B530;
    box-shadow: inset 6px 0 0 0 #f9f9f9;
}

.forward {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid #E7B530;
    /*margin: 20px auto 10px auto;*/
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    left: calc(100% - 35px);
    top: 12px;
}


.forward:before {
    content: '';
    position: absolute;
    top: -15px;
    left: -23px;
    bottom: -15px;
    right: -7px;
    border-radius: 50%;
    border: 2px solid #E7B530;
    z-index: 2;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
}

.forward:hover:before, .forward:focus:before {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}

.forward.active {
    border-color: transparent;
}

.backward {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 12px solid #E7B530;
    /*margin: 20px auto 10px auto;*/
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    left: calc(100% - 85px);
    top: 12px;
}

.backward:before {
    content: '';
    position: absolute;
    top: -15px;
    left: -8px;
    bottom: -15px;
    right: -22px;
    border-radius: 50%;
    border: 2px solid #E7B530;
    z-index: 2;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
}

.backward:hover:before, .backward:focus:before {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}

.backward.active {
    border-color: transparent;
}

.rc-slider-mark-text-active{
    color: #E7B530;
}